import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Logo from '../components/Logo'
import Header from '../components/Header'
import ModalContainer from '../components/ModalContainer'
import Footer from '../components/Footer'

const AboutPage = ({ 
  data: { 
    banner, 
    mobilebanner, 
    footerbanner, 
    logo, 
    logohover 
  }, 
  pageContext: { 
    locale, 
    slug,
    content,
    alternates,
  }, 
  location 
}) => {
  
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const banners = {
    desktop: banner.edges[0].node.original.src,
    mobile: mobilebanner.edges[0].node.original.src,
    footerBanner: footerbanner.edges[0].node.original.src,
  }
  
  return (
    <React.Fragment>
      <SEO
        pathname={location.pathname}
        locale={locale}
        banner={banners.desktop}
        slug={slug}
      />
      <Header
        slug={slug}
        locale={locale}
        location={location}
        alternates={alternates}
      />
      <MainWrapper>
        <section
          className="intro desktop"
          style={{
            paddingTop: '80px',
            background: `url(${banners.mobile}) top no-repeat`,
            backgroundSize: 'cover',
          }}
        ></section>

        <section
          className="intro mobile auto-height"
          style={{ paddingTop: "5rem" }}
        >
          <img src={banners.mobile} style={{ width: '100%' }} alt="David Jarre, Magic From Home" />
        </section>

        <section className="content regular" style={{ paddingBottom: "6rem" }}>
          <div className="container magicFromHome">
            <div className="row">
              <div className="pageIntro col nf np">
                {
                  content[0].map((item, index_) => {
                    return <p key={`about-paragraph-${index_}`}>{item}</p>
                  })
                }
              </div>
            </div>
            
            <Logo
              logo={logo.edges[0].node.publicURL}
              logohover={logohover.edges[0].node.publicURL}
              className="page"
            />

            <div className="row" style={{ maxWidth: "840px", margin: "0 auto" }}>
              {
                content[1].map((item, index_) => {
                  return (
                    <div className="col-6 np" key={`about-paragraph-block-${index_}`}>
                      {
                        item.map((i, index) => {
                          return <p key={`about-paragraph-${index}`}>{i}</p>
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          <ModalContainer
            triggerText={i18n.buttons[0]}
            locale={locale}
            button={true}
          />
        </section>
      </MainWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default AboutPage
export const pageQuery = graphql`
  query About {
    banner: allImageSharp(filter: {original: {src: {regex: "/background-mfh/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/RM_75532-1/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    footerbanner: allImageSharp(filter: {original: {src: {regex: "/mfh-footer-banner-full/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`